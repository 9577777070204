export const environment = {
    production: true,
    hmr: false,
    test: false,
    commonConfig: {
        'timeConfig': {  // 时间参数配置
            'localStorageTimeOut': 30  // 时间不操作,自动退出
        },
        'numberConfig': {    // 数值参数配置
            'exportDataNum': 50000   // 一次性导出的最大值
        },
        'pageNum': 1,
        'pageCount': 10,
        'userOssOrCos': 'OSS',
        'OSS': {
            'config': {
                region: 'oss-cn-qingdao',
                accessKeyId: 'LTAI5t7MkNfMBUvTM79aqpZe',
                accessKeySecret: 'QwPVndbe5KZGsLjO89ALYerwvtvvpb',
                bucket: 'hdbs-oss',
                secure: true,
                timeout: 180000
            },
            'domain': 'https://hdbs-oss.oss-cn-qingdao.aliyuncs.com',
            'ossPath': 'iron/assets/'
        }
    },
    'hdbsAssets': {
        'jsonId': 'hdbsAssets',
        'baiduCenter': '110.7736,32.65696',
        'MysqlPF': 'assetsDataResources',
        'MysqlSearchPF': 'assetsReadOnlyDataResources',
        'mongoPF': '',
        'NodeJsServer': 'https://dfmc-hulk.xueshu.ren',
        'ExportServer': 'https://dfmc-hulk.xueshu.ren',
        'quickSliver': 'https://dfmc83.xueshu.ren:4001/quicksilver',
        'NodeJsApiVersion': 'v1',
        'AutoPlatform': 'https://dfmc83.xueshu.ren:4001/auto/HdbsAP',
        'javaPlatform': '',
        'hdbsthor': {
            'zuul': 'https://dfmc83.xueshu.ren:4001/hdbsthor_zuul',
            'accessToken': 'ed43c7fc5b1c8ee7e45d50977814bddf'
        },
        'hdbsthor_right': {
            'getway': 'https://dfmc83.xueshu.ren:4001/hdbsthor_right',
            'accessToken': '90c0e25c0cc8070984023e3afa18a42c'
        },
        'hdbsthor_assets': {
            'getway': 'https://dfmc83.xueshu.ren:4001/hdbsthor_assets',
            'getway_callBack': 'https://dfmc83.xueshu.ren:4001/hdbsthor_assets',
            'accessToken': '90c0e25c0cc8070984023e3afa18a42c'
        },
        'wordProcess': {
            'getway': 'https://dfmc83.xueshu.ren:4001/hdbsthor_assets/AppApi/WordProcessController/',
            'accessToken': '90c0e25c0cc8070984023e3afa18a42c'
        },
        'menu_list': 'assets/menu_list.json',
        'app_list': 'assets/app_list.json',
        'login_check': 'assets/login_check.json',
        'login_count': 'assets/login_count.json',
        'btn_list': 'assets/user_menu_action.json',
        'btn_detail': 'assets/user_menu_detail_action.json',
        'btn_readOnly': 'assets/user_menu_readOnly_action.json',
        'btn_workflow': 'assets/user_menu_workflow_action.json',
        'useTodoCenter': true,
        'logoConfig': {
            'companyInfo': '东风通信技术有限公司提供技术支持',
            'welcomeInfo': '东诚惠众智慧资产管理平台',
            'systemName': '东诚惠众智慧资产管理平台',
            'sysTitle': '东诚惠众智慧资产管理平台',
        },
        'sql_path': 'assets',
        'userOssOrCos': 'OSS',
        'debug': true,
        'COS': {
            'config': {
                SecretId: 'AKIDRmy1taKThibJpAnI5h7NTO7f1B9wBxBD',
                SecretKey: 'zmNm276xc6OVRCmEUZS8d3HLDuPjrpbB',
                bucket: 'sino-1252602947',
                region: 'ap-shanghai',
                appId: '1252602947'
            },
            'domain': 'https://sino-1252602947.cos.ap-shanghai.myqcloud.com',
            'cosPath': 'assets/'
        },
        'OSS': {
            'config': {
                region: 'oss-cn-qingdao',
                accessKeyId: 'LTAI5t7MkNfMBUvTM79aqpZe',
                accessKeySecret: 'QwPVndbe5KZGsLjO89ALYerwvtvvpb',
                bucket: 'hdbs-oss',
                secure: true,
                timeout: 180000
            },
            'domain': 'https://hdbs-oss.oss-cn-qingdao.aliyuncs.com',
            'ossPath': 'iron/assets/'
        }
    }
};
